import React from 'react';
import { withRouter } from "react-router-dom";
import { TitleXl, Row, Col4 } from "components/elements";
import { LeadersBySpecialtyContainer, CardContainer,EmptyPlaceholder } from './elements';
import ViewAll from 'components/ViewAll';


function LeadersBySpecialtyCards({ headline, ctaText, content }) {
  const cardData = [
    {
      title: 'Integrated Communications',
      image: '/images/sm-intg-mark-com.png',
      url: '/integrated-communications'
    },
    {
      title: 'Advertising',
      image: '/images/sm-adv-med-sol.png',
      url: '/advertising'
    },
    {
      title: 'Activation',
      image: '/images/sm-activation-header.png',
      url: '/activation'
    },
    {
      title: 'Medical Communications',
      image: '/images/sm-med-comm-head.png',
      url: '/medical'
    },
    {
      title: 'Analytics + Insights',
      image: '/images/sm-data-ai-sol.png',
      url: '/analytics-and-insights'
    },
  ];

  const dataToDisplay = content?.elements || cardData;
  const shouldAddEmptySpace = dataToDisplay.length === 5;

  return (
    <LeadersBySpecialtyContainer>
      <TitleXl fontWeight="900">
        {headline}
      </TitleXl>
      <Row rowGap="30px">
        {dataToDisplay.map((data, index) => (
          <Col4 key={index}>
            <CardContainer backgroundImage={data.image} title={""}>
              <TitleXl fontWeight="900">
                {data.title}
              </TitleXl>
              <ViewAll 
                link={data.url || data.ctaLink} 
                title={ctaText || data.ctaText} 
                borderColor="#fff" 
                titleColor="#fff" 
                hoverBackground="#fff" 
                svgColor="#fff" 
                width="255px" 
                height="38px" 
              />
            </CardContainer>
          </Col4>
        ))}
        {shouldAddEmptySpace && (
          <EmptyPlaceholder key="empty-placeholder">
            {/* Empty div for spacing, visible only on desktop */}
            <div style={{ height: '100%', visibility: 'hidden' }}></div>
          </EmptyPlaceholder>
        )}
      </Row>
    </LeadersBySpecialtyContainer>
  );
}

export default withRouter(LeadersBySpecialtyCards);

import React from 'react';
import { SubMenu } from "../elements";

// Components
import InfoCard from "./InfoCard";
import ContentCard from "./ContentCard";
import { Col3, Col4, Col9, Row } from "components/elements";

function AlchemyMenu(props) {
  const clearFilters = () => {
    if (localStorage.getItem("location") !== null)
      localStorage.removeItem('location')

    if (localStorage.getItem("capability") !== null)
      localStorage.removeItem('capability')
  }


  const cards = props.data?.subNavigation?.cards || [];
  const isLastQuadrantEmpty = cards.length === 5;

  return (
    <SubMenu showSubMenu={props.open}>
      <Col3>
        <InfoCard rows={3} cols={2} data={props.data} />
      </Col3>
      <Col9>
        <Row>
          {cards.map((card, index) => (
            <Col4 onClick={clearFilters} key={index}>
              <ContentCard rows={3} cols={2} data={card} borderBottom="none" minHeight="105px" />
            </Col4>
          ))}
          {isLastQuadrantEmpty && (
            <Col4 key="empty-placeholder">
			<div style={{
				height: '104px',
				borderTop: '1px solid black',
				borderLeft: '1px solid black',
			}}>

			</div>
			</Col4> // This adds an empty space for the last quadrant
          )}
        </Row>
      </Col9>
    </SubMenu>
  )
}

export default AlchemyMenu;
